import React, { Component } from 'react';
import './GroupCards.css';
import styles from './GroupCards.module.scss';
import _ from 'lodash';
import GroupCard from './GroupCard/GroupCard';
import AddButton from './AddButton/AddButton';
import LoadingSpinner from '../basicElements/LoadingSpinner/LoadingSpinner';
import SearchBar from '../basicElements/SearchBar/SearchBar';
import { withTranslation } from 'react-i18next';
// import GroupCardPagination from './GroupCard/GroupCardPagination/GroupCardPagination';
import BackButton from '../basicElements/BackButton/BackButton';

import Button from '../basicElements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { getSortFunct } from '../helpers';
import Tag from '../basicElements/TagsInput/Tag/Tag';
import GroupList from './GroupCard/GroupList';

/**
 * Renders Groups and their items in pretty boxes.
 * 
 * Props:
 *  - groups: (required) array in form of 
 *      [
            {
                id : 1234,

                the following is DEPRECATED,
                only when using inconsistent / outdated API
                instead returned from getGroup (see below)
                
                title : "Group 1",
                items : [
                    {   id: 123, 
                        title: "ABC", 
                        onClick: func(id, title) },
                        disabled: false, // changes style only
                        highlighted: false, // changes style only
                        icon: string, // if null, default is shown
                        type: string, // mostly for channels (TODO: implement?)
                    ],
            }, ...
        ]

    - getGroup(id, showDetailView): gets specific group from widget-specific layer (ex. a company with its users)
    - showDetailView(): to open detailview in widget

    - changeGroupTitle(id, value): function that gets called when group title gets edited in the textfield
    - deleteGroup(id): function that gets called when a group-delete button is pressed
    - addGroup(title)
    - searchTerm: prefilling for the cardfilter
    - search(searchterm)
    - addItem(groupId)

    - useGravatar (see GroupCardsContainer)
    - uneditable
    - editItemIcon (boolean)
 */
class GroupCards extends Component {
    pageSize = 2;

    constructor(props) {
        super(props);

        this.addGroup = this.addGroup.bind(this);
        this.groupAddTriggered = false;

        this.state = {
            groups: [],
            currentPageNum: 1,
            currentPage: [],
        }

        this.createClassString = this.createClassString.bind(this);
        this.renderGroup = this.renderGroup.bind(this);
    }

    componentDidMount() {
        this.setState({
            groups: [],
            currentPageNum: 1,
            currentPage: []
        }, this.getPage);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.groups, this.props.groups)) {
            this.componentDidMount();
        }

        if (this.groupAddTriggered && prevProps.groups && prevProps.groups.length < this.props.groups.length) {
            this.groupAddTriggered = false;
            this.onGroupAdded();
        }
    }

    onGroupAdded() {
        if(this.groupCardRef){  // todo: ensure we only call this when ref is defined
            this.groupcardsRef.scrollTo({
                top: this.groupCardRef.offsetHeight * this.props.groups.length,
                behavior: 'smooth'
            });
        }
    }

    addGroup(title) {
        this.groupAddTriggered = true;
        this.props.addGroup(title);
    }

    // mostly about spacing depending on which elements are shown
    createClassString() {
        let out = 'groupcards';
        
        if (!this.props.search)
            out += ' groupcards-nosearch';

        return out;
    }

    renderGroup(group) {
        // TODO: the random keys here are not a real fix, as there is a probability of weird things happening still
        // when keys are the same, there are probable render issues, like groups appearing multiple times where they shouldn't

        // groupid and title because of /me/company vs companies in managedCompanyGroups
        // (own company can be in both and therefore be displayed twice)
        if (this.props.selectedGroup !== group.id
            || this.props.selectedGroupTitle !== group.title) {
            return (
                <div
                    key={group.id + Math.random() * 10000}
                    onClick={() => this.props.openGroup(group.id, group.title)}
                    className="groupcard-unopened"
                    data-testid={`groupcard-${group.title}`}
                >
                    <span className={styles.title}>
                        {group.title}
                        {group.titleTags &&
                            <span className={styles.titleTags}>
                                {group.titleTags?.map(t=><Tag>{t}</Tag>)}
                            </span>
                        }
                    </span>
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
            )
        }

        // TODO; remove compatibility layer, as soon as all groupcard widgets use the getGroup feature
        const g = this.props.getGroup ? this.props.fetchedGroups[group.id] : group;

        if (this.props.mode === 'list') {
            return (
                <GroupList
                    key={g.id + Math.random() * 10000}
                    id={g.id}
                    title={g.title}
                    items={g.items.sort(getSortFunct('title'))}
                    secondaryItems={group.secondaryItems}
                    changeGroupTitle={this.props.changeGroupTitle}
                    showGroupView={this.props.showGroupView}
                    deleteGroup={this.props.deleteGroup}
                    addItem={this.props.addItem}
                    useGravatar={this.props.useGravatar}
                    uneditable={this.props.uneditable}
                    editItemIcon={this.props.editItemIcon}
                    onDrop={this.props.onDrop}
                    itemRef={(r) => { this.groupCardRef = r; }}
                    getItem={this.props.getItem}
                    listColumns={this.props.listColumns}
                    customTableRenderers={this.props.customTableRenderers}
                    isUserManager={this.props.isUserManager}
                    disabledColumnFilters={this.props.disabledColumnFilters}
                />);
        } else {
            return (
                <GroupCard
                    key={g.id + Math.random() * 10000}
                    id={g.id}
                    title={g.title}
                    items={g.items}
                    secondaryItems={group.secondaryItems}
                    changeGroupTitle={this.props.changeGroupTitle}
                    showGroupView={this.props.showGroupView}
                    deleteGroup={this.props.deleteGroup}
                    addItem={this.props.addItem}
                    addItemTitle={group.createTitle}
                    addItemDisabled={group.createDisabled}
                    useGravatar={this.props.useGravatar}
                    uneditable={this.props.uneditable || group.editDisabled}
                    editItemIcon={this.props.editItemIcon}
                    onDrop={this.props.onDrop}
                    itemRef={(r) => { this.groupCardRef = r; }}
                    groupCardtitleInfo={this.props.groupCardtitleInfo}
                    titleTags={group.titleTags}
                />);
        }
    }

    renderFooter() {
        if (!this.props.getGroup && !this.props.secondaryGoBack)
            return;

        return (
            <>
                <div className={'groupcards-back'}>
                    { this.props.secondaryGoBack &&
                        <BackButton onClick={this.props.secondaryGoBack} />
                    }
                </div>

                {/* <div className={'groupcards-listtoggle'}>
                    { this.props.mode &&
                        <Button
                            type='primary'
                            onClick={this.props.toggleMode}
                        >
                            { this.props.mode === 'list'
                            ? 'Items View'
                            : 'List View'
                            }
                        </Button>
                    }
                </div> */}
            </>
        );
    }

    render() {
        if (!this.props.groups) {
            return <LoadingSpinner />;
        }

        // translation method with fallback
        const t = this.props.t || (k => k);
        const {groupName, itemName} = this.props;

        return (
            <div className="groupcards-wrapper">
                {this.props.search && (
                    <SearchBar
                        placeholder={`${t('Filter')} ${groupName ? groupName.n : t("Groups")} & ${itemName ? itemName.n : "Items"}`}
                        searchTermPrefill={this.props.searchTerm}
                        search={this.props.search}
                    />
                )}

                {this.props.groups.length === 0 ? (
                    <div className="groupcards-nofound"> {t("No Groups found")} </div>
                ) : (
                    <article data-testid="groupcards" className={this.createClassString()} ref={(r) => { this.groupcardsRef = r; }}>
                        {_.map(this.props.groups.sort(getSortFunct('title')), (group)=>{
                            return this.renderGroup(group)
                        })}
                    </article>
                )}

                { this.renderFooter() }

                {this.props.addGroup && (
                    <AddButton addGroup={this.addGroup} buttonProps={{ariaLabel:'add group'}} />
                )}
            </div>
        );
    }
}

export default withTranslation()(GroupCards);