import { useTranslation } from 'react-i18next';
import Popup from '../common/basicElements/Popup/Popup';
import Button from '../common/basicElements/Button/Button';
import { useState } from 'react';
import { removeChannels } from 'src/redux/actions/actions.channelGroups';
import { useDispatch } from 'react-redux';
import { render } from '@testing-library/react';
import APIChannelGroups from 'src/API/APIChannelGroups';

interface ForbiddenChannelsHandlerProps {
    channelGroupId: number;
    channelIdsInGroup: number[];
    forbiddenChannels: any[];
    onCloseModal: () => void;
    onDeletedChannels: (deletedAll: boolean) => void;
}

function ForbiddenChannelsHandler({
    channelGroupId,
    channelIdsInGroup,
    forbiddenChannels,
    onCloseModal,
    onDeletedChannels,
}: ForbiddenChannelsHandlerProps) {
    const { t } = useTranslation();
    const [isDeletingChannels, setIsDeletingChannels] = useState(false);
    // const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const dispatch = useDispatch();

    const allGroupChannelsForbidden = channelIdsInGroup.length === forbiddenChannels.length;

    const renderPopup = () => {
        return (
            // @ts-ignore
            <Popup size="auto" wrapperStyle={{ zIndex: 9003 }} blockContent={false}>
                <div>
                    <h3>{t('search_forbidden_channels_title')}</h3>
                    <div>
                        {allGroupChannelsForbidden ? t('search_forbidden_channels_only')
                        : t('search_forbidden_channels_no_access')}
                        <ul>
                            <li>{t('search_forbidden_channels_delete')} {allGroupChannelsForbidden && t('search_forbidden_channels_add_channels')}</li>
                            <li>{t('search_forbidden_channel_change_settings')}</li>
                        </ul>
                    </div>
                    <p>
                        {t('number of affected channels')}: {forbiddenChannels.length}
                    </p>
                    <div>
                        <Button
                            disabled={isDeletingChannels}
                            onClick={async () => {
                                setIsDeletingChannels(true);
                                const res:any = await dispatch(removeChannels(channelGroupId, forbiddenChannels));
                                setIsDeletingChannels(false);
                                onCloseModal();
                                onDeletedChannels(allGroupChannelsForbidden);
                            }}
                            type="danger"
                        >
                            {t('delete_from_group')}
                        </Button>
                        <Button
                            type="cancel"
                            onClick={() => {
                                onCloseModal();
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                        {/* {errorMsg && <div>{errorMsg}</div>} */}
                    </div>
                </div>
            </Popup>
        );
    };

    return renderPopup();
}

export default ForbiddenChannelsHandler;
