import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './ClipColumn.module.scss';
//@ts-ignore
import AutoSizer from 'react-virtualized-auto-sizer';
//@ts-ignore
import { VariableSizeList as List, areEqual } from 'react-window';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import { ReactWindowProps } from 'src/Widgets/common/MentionResults/MentionResults.types';
//import useChannelIcons from 'src/hooks/useChannelIcons';
import APIMentions from 'src/API/APIMentions';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
import Skeleton from '../../Skeleton/Skeleton';
import ClipCard from '../../ClipCard/ClipCard';
import { useTranslation } from 'react-i18next';

import Button from 'src/Widgets/common/basicElements/Button/Button';
import APIClips from 'src/API/APIClips';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faList } from '@fortawesome/free-solid-svg-icons';
import APIChannels from 'src/API/APIChannels';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import LoadingIndicator from 'src/Widgets/common/basicElements/LoadingIndicator/LoadingIndicator';
import Popup from 'src/Widgets/common/basicElements/Popup/Popup';
//import { sleep } from 'src/Widgets/common/helpers';

const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const ClipColumn = ({
    listKey,
    filteredDataToDisplay,
    isSearch,
    processedIndices,
    fetchedItems,
    setFetchedItems,
    clipDetail,
    setClipDetail,
    isEdited,
    isEditorial,
    activeFilter,
    isMentionFindrApp,
    backFillSelectionVisible,
    setBackFillSelectionVisible,
    clipStates,
    updateClipState,
    fetchClipStates,
    stateUpdatesObserver,
    wFeedAbortCtrl,
    messageHandler,
    tryQuery,
    setIsClipsBackfilled,
    selectedCardElements,
    setSelectedCardElements,
    filterClipsByBackfill,
    setModalType
}: MentionsVisualizerTypes.ClipColumnProps) => {
    const ongoingRequests: Set<number> = new Set();
    const isMobile = useIsScreenSize(1280);
    const { t } = useTranslation();

    const [movedItemsMessage, setMovedItemsMessage] = useState<string>('');
    const [backfilling, setBackfilling] = useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    // const [movedItems, setMovedItems] = useState({
    //     moved: 0,
    //     notMoved: 0
    // });

    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const listRef = useRef<List>(null);

    const rowHeights = useRef<{ [key: number]: number }>({});

    const fetchNotificationDetails = async (notifID: number) => {
        if (
            !clipDetail[notifID] &&
            !fetchedItems.has(notifID) &&
            notifID !== undefined &&
            !ongoingRequests.has(notifID)
        ) {
            ongoingRequests.add(notifID);
            try {
                const res = await APIMentions.getNotificationResults(
                    isSearch ? notifID + 't' : notifID, [39003]
                );
                if(res.rc === 0){
                    setClipDetail((prevData: MentionsVisualizerTypes.ClipDetailType) => ({
                        ...prevData,
                        [notifID]: res.data
                    }));
                    setFetchedItems((prevItems: Set<number>) => {
                        const newItems = new Set(prevItems);
                        newItems.add(notifID);
                        return newItems;
                    });
                }else if(res.data.rc === 39003){
                    //show popup message and reload page
                    setModalType('multipleWidgetsRestriction');
                }
            } finally {
                ongoingRequests.delete(notifID);
            }
        }
    };

    const handleSelectAllCheckboxes = () => {
        if (selectedCardElements.length === filteredDataToDisplay.length) {
            setSelectedCardElements([]);
        } else {
            setSelectedCardElements(filteredDataToDisplay);
        }
    };

    //fetch notification details when scrolling
    let stateObserverCalled = false;

    const handleItemsRendered = debounce(
        ({ visibleStartIndex, visibleStopIndex }: any) => {
            const notifIDsDetailsToFetch = [];
            const notifIDsStatesToFetch = [];

            const processDuplicates = (duplicates: any[]) => {
                duplicates.forEach((duplicate: any) => {
                    notifIDsStatesToFetch.push(duplicate.notifID);
                    if (!fetchedItems.has(duplicate.notifID || duplicate.notifid)) {
                        notifIDsDetailsToFetch.push(duplicate.notifID || duplicate.notifid);
                    }
                });
            };
    
            // Loop through the visible range of items
            for (let index = visibleStartIndex; index <= visibleStopIndex; index++) {
                const item = filteredDataToDisplay[index];
                notifIDsStatesToFetch.push(item.notifID);

                 // Process duplicates
                 if (item.duplicates && item.duplicates.length > 0) {
                    processDuplicates(item.duplicates);
                }
    
                // Fetch details for the super notification
                if (!fetchedItems.has(item.notifID || item.notifid) && !processedIndices.has(index)) {
                    processedIndices.add(index);
                    notifIDsDetailsToFetch.push(item.notifID);
                }
    
                // Check for basicNotifications and fetch their details as well
                if (item.basicNotifications && item.basicNotifications.length > 0) {
                    item.basicNotifications.forEach((basicNotification: any) => {
                        if (!fetchedItems.has(basicNotification.notifID || basicNotification.notifid)) {
                            notifIDsDetailsToFetch.push(basicNotification.notifID || basicNotification.notifid);
                        }
                    });
                }
            }
    
            // Fetch details for each notifID in the array
            notifIDsDetailsToFetch.forEach((notifID) => {
                const validNotifID: string | number = notifID;
                fetchNotificationDetails(validNotifID as any);
            });
    
            if (notifIDsStatesToFetch.length > 0 && 
                isEditorial && clipStates.cl) {
                const initialClipState = clipStates.cl;
                wFeedAbortCtrl.current.abort();
                wFeedAbortCtrl.current = new AbortController();
                fetchClipStates(notifIDsStatesToFetch)
                    .then(() => {
                        if (clipStates.cl === initialClipState && !stateObserverCalled) {
                            stateObserverCalled = true;
                            stateUpdatesObserver(clipStates.cl);
                            stateObserverCalled = false;
                        }
                    })
                    .catch((error: any) => {
                        if (error.name !== 'AbortError') {
                            console.error('Failed to fetch clip states:', error);
                        }
                    });
            } 
        },
        300
    );

     // Dynamically calculate the height of each row
     const getRowHeight = 
        (index: number) => {
            const clip = filteredDataToDisplay[index];
            const defaultHeight = isMobile ? 350 : 130; // Default height for a card

            // Calculate height based on content if details are available
            if (clipDetail[clip.notifID]) {
                const descriptionHeight = clipDetail[clip.notifID].desc
                    ? clipDetail[clip.notifID].desc.length * 0.1
                    : 0;

                const basicHeight = clip.basicNotifications
                    ? clip.basicNotifications.length * 10
                    : 0;

                const duplicateHeight = clip.duplicates && clip.duplicates.length > 0
                    ? (clip.duplicates.some(
                        (duplicate: any) => duplicate.dupType !== 'channel' && duplicate.dupType !== '*' && duplicate.dupType !== 'network'
                    ) ? clip.duplicates.length * 55 : clip.duplicates.length * 5)
                    : 0;    
            
                return (
                    defaultHeight +
                    descriptionHeight +
                    basicHeight +
                    (activeFilter.foldEnabled ? duplicateHeight : 0)
                );
            }

            return defaultHeight;
        }

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [filteredDataToDisplay, getRowHeight]);

        //sort clips by hitTimeTS for editorial view ascending order
        if (isEditorial) {
            filteredDataToDisplay.sort((a, b) => a.hitTimeTS - b.hitTimeTS);
        }


    //keyframe row component - react window
    const KeyframeRow = memo(({ index, style }: ReactWindowProps) => {

        const clip = filteredDataToDisplay[index];
        const clipEditorialState = clipStates?.states?.find(
            (state: { id: string; state: string, userid: string }) => Number(state.id) === clip.notifID
        ) || { state: '' , id: '', userid: ''};

        const clipDetailData = clipDetail[clip.notifID || clip.notifid];
        if (!clipDetailData) {
            return (
                <div style={style} key={clip.channelid}>
                    <Skeleton width="100%" height="120px" isClipCard />
                </div>
            );
        }

        const basicNotificationDetails = clip.basicNotifications?.map(
            (basicNotif: any) => clipDetail[basicNotif.notifID] || {}
        );

        const duplicateDetails = clip.duplicates?.map(
            (dup: any) => clipDetail[dup.notifID] || {}
        );

        const duplicateEditorialStates = clip.duplicates?.map(
            (dup: any) => clipStates?.states?.find(
                (state: { id: string; state: string, userid: string }) => Number(state.id) === dup.notifID
            ) || { state: '', id: '', userid: '' }
        );

        rowHeights.current[index] = getRowHeight(index);
        return (
            <ClipCard
                clip={clip}
                clipEditorialState={clipEditorialState}
                clipStates={clipStates}
                clipDetailData={{
                    ...clipDetailData,
                    icon: APIChannels.getIconUrl(clip.channelNumber)
                }}
                basicNotifications={basicNotificationDetails}
                duplicates={duplicateDetails}
                duplicateEditorialStates={duplicateEditorialStates}
                style={style}
                isEdited={isEdited}
                isEditorial={isEditorial}
                isSearch={isSearch}
                activeFilter={activeFilter}
                isMentionFindrApp={isMentionFindrApp}
                backFillSelectionVisible={backFillSelectionVisible}
                setSelectedCardElements={setSelectedCardElements}
                selectedCardElements={selectedCardElements}
                updateClipState={updateClipState}
                messageHandler={messageHandler}
            />
        );
    }, areEqual);

    if (filteredDataToDisplay.length === 0) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}
            >
                {t('Selected channels not in')}
                {activeFilter.type}
            </div>
        );
    }

    const backFillResultsToNotifications = async () => {
        if (isSearch || tryQuery) {
            setBackfilling(true);
            const notifIDs = selectedCardElements.map(
                (item: MentionsVisualizerTypes.Clip) => item.notifID || item.notifid
            );
            const profileIdToUse = isSearch && !tryQuery ? filteredDataToDisplay[0].profileID : tryQuery?.profileID;
            if(profileIdToUse !== undefined){
        
            let response: any = null;
    
            // Start progress interval
            intervalRef.current = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 95) return 95; // Cap progress to 95%
                    return prevProgress + 1;
                });
            }, 100);
    
            try {
                response = await APIClips.makeClipsPermanent(
                        profileIdToUse,
                        notifIDs
                    );
    
                    const notMovedItems = selectedCardElements.length - response.data.moved;
                if (response.data.moved && response.rc === 0) {
                    filterClipsByBackfill(notifIDs);
                    setIsClipsBackfilled(true);
                    // setMovedItems({
                    //     moved: response.data.moved,
                    //     notMoved: notMovedItems,
                    // });
                    setMovedItemsMessage(
                        notMovedItems > 0
                            ? `${notMovedItems} ${t('clips already moved')}.\n` + 
                              `${response.data.moved} ${t('clips moved to notifications')}`
                            : `${response.data.moved} ${t('clips moved to notifications')}`
                    );
                } else if (response.data.moved === 0) {
                    setMovedItemsMessage(
                        `${t('No clips moved to notifications')}, ${t('clips already moved')}`
                    );
                } else {
                    setMovedItemsMessage('');
                }
            } catch (error) {
                console.error('Error backfilling notifications:', error);
                setMovedItemsMessage(`${t('Error occurred while backfilling clips')}`);
            } finally {
                clearInterval(intervalRef.current as NodeJS.Timeout);
                setProgress(100);
    
                setTimeout(() => {
                    setProgress(0);
                    setMovedItemsMessage('');
                    setBackfilling(false);
                    setIsClipsBackfilled(false);
                    if (response?.data?.moved > 0) {
                        setSelectedCardElements([]);
                        setBackFillSelectionVisible(false);
                    }
                }, 2000);
            }
            }
        }
    };
    
    

    return (
        <div
            className={
                backFillSelectionVisible
                    ? styles.clipsContentContainerBackFill
                    : styles.clipsContentContainer
            }
            data-testid="keyframe-row"
        >
            {backFillSelectionVisible && (
                <div className={styles.backFillSelectContainer}>
                    <div
                        onClick={handleSelectAllCheckboxes}
                        className={styles.backFillSelectAll}
                    >
                        <input
                            type="checkbox"
                            className={styles.checkbox}
                            checked={
                                selectedCardElements.length ===
                                filteredDataToDisplay.length
                            }
                            onChange={(e) => e.stopPropagation()}
                        />
                        <FontAwesomeIcon
                            icon={faCheckDouble}
                            className={styles.selectAllIcon}
                            title={t('Select all')}
                        />
                        <div
                            className={
                                selectedCardElements.length !== 0
                                    ? styles.selectedCount
                                    : styles.selectedCountNotAllSelected
                            }
                        >
                            {selectedCardElements.length}/{filteredDataToDisplay.length}{' '}
                            {t('Selected')}
                        </div>
                    </div>
                    {backfilling && (
                        //@ts-ignore
                        <Popup
                            blockContent
                            wrapperStyle={{
                                position: 'fixed',
                                insetBlockStart: 'unset',
                                insetBlockEnd: 0,
                                height: 'calc(100% - 2.2rem)'
                            }}
                            size="auto"
                        >
                            {movedItemsMessage !== '' ? (
                                <div className={styles.progressMessage}>
                                    {movedItemsMessage}
                                </div>
                            ) : (
                                <div className={styles.progressMessage}>
                                    {t('Moving clips to notifications')}
                                </div>
                            )}
                            <div style={{ marginBlockStart: '1rem' }}>
                                {progress !== 100 && <LoadingSpinner size="4rem" />}
                            </div>
                        </Popup>
                    )}
                    {selectedCardElements.length > 0 ? (
                        <div className={styles.backFillButtons}>
                            {/* {movedItemsMessage ? (
                            <span className={styles.movedItemsMessage}>
                                {movedItemsMessage}
                            </span>
                        ) : ( */}
                            <Button
                                type={'secondary'}
                                btnClass={styles.backFillButton}
                                disabled={selectedCardElements.length === 0}
                                onClick={backFillResultsToNotifications}
                            >
                                {t('Backfill')}
                            </Button>
                            {/* )} */}
                            <Button
                                type={'danger'}
                                btnClass={styles.backFillButton}
                                disabled={selectedCardElements.length === 0}
                                onClick={() => {
                                    setSelectedCardElements([]);
                                    setBackFillSelectionVisible(false);
                                    setBackfilling(false);
                                }}
                            >
                                {'Cancel'}
                            </Button>
                        </div>
                    ) : (
                        <span className={styles.backFillMessage}>
                            {t('Please select clips to backfill')}
                        </span>
                    )}
                </div>
            )}
            <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                    <List
                        key={listKey}
                        height={height}
                        itemCount={filteredDataToDisplay.length}
                        itemSize={(index: number) => getRowHeight(index)}
                        width={width}
                        onItemsRendered={handleItemsRendered}
                        ref={listRef}
                    >
                        {KeyframeRow}
                    </List>
                )}
            </AutoSizer>
        </div>
    );
};

export default ClipColumn;
